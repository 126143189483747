import {
  handleResponse,
  axiosInstanceNoAuth
} from '../../../app/utilities/helpers/'
import qs from 'qs'

const handleGetLinkData = (id) => axiosInstanceNoAuth.get(`links?link_id=${id}`).then(handleResponse)

const handleSaveEvent = (data) => axiosInstanceNoAuth.post(`assetevents`, data).then(handleResponse)

const handleCreateLinkContact = (data) => axiosInstanceNoAuth.post('link/contact', data).then(handleResponse)

const handleValidatePassword = (data) => axiosInstanceNoAuth.post('link/password', data).then(handleResponse)

const handleCreateSession = (data) => axiosInstanceNoAuth.post('link/sessions', data).then(handleResponse)

const handleGetLinks = (data) => {
  const param = qs.stringify(data)
  return axiosInstanceNoAuth.get(`/compose/send-shallow?${param}`).then(handleResponse)
}

const handleCompose = (data) => axiosInstanceNoAuth.post('compose/resend', data).then(handleResponse)


export const linkService = {
  handleGetLinkData,
  handleSaveEvent,
  handleCreateLinkContact,
  handleValidatePassword,
  handleCreateSession,
  handleGetLinks,
  handleCompose
}
