import React from 'react';

const LoadingSpinner = ({ colorClassName }) => {
  return (
    <div className="flex justify-center items-center">
      <div className={`animate-spin rounded-full h-5 w-5 border-b-2 ${colorClassName ? colorClassName : 'border-white'}`}></div>
    </div>
  );
};

export default LoadingSpinner;
