import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

export const onDateDDMMMYYYY = (d) => {
  const dt = String(new Date(d)).split(' ');
  return `${dt[2]} ${dt[1]} ${dt[3]}`;
};

export const dateCalculation = (passedDateData, currentDateData) => {
  let currentDate = currentDateData;
  let passedDate = passedDateData;
  if (passedDate === currentDate) return 'now';

  if (passedDate > currentDate) {
    const dtmp = currentDate;
    currentDate = passedDate;
    passedDate = dtmp;
  }

  const year1 = passedDate.getFullYear();
  const year2 = currentDate.getFullYear();

  const month1 = passedDate.getMonth() + 1;
  const month2 = currentDate.getMonth() + 1;

  const day1 = passedDate.getDate();
  const day2 = currentDate.getDate();

  const hour1 = passedDate.getHours();
  const hour2 = currentDate.getHours();

  const minute1 = passedDate.getMinutes();
  const minute2 = currentDate.getMinutes();
  const second1 = passedDate.getSeconds();
  const second2 = currentDate.getSeconds();
  let dateDiffValue = '';
  if (year1 !== year2) {
    const ymc = (12 - month1) + month2;
    if (ymc + month2 <= 12) {
      dateDiffValue = `${ymc + month2} months`;
    } else {
      const y = year2 - year1;
      dateDiffValue = `${y > 1 ? (`${y} years`) : (`${y} year`)}`;
    }
  }
  if (year1 === year2) {
    if (month1 === month2) {
      if (day1 === day2) {
        if (hour1 === hour2) {
          if (minute1 === minute2) {
            const s = second2 - second1;
            dateDiffValue = (second1 === second2) ? 'now' : `${s > 1 ? (`${s} seconds`) : (`${s} second`)}`;
          } else {
            const m = minute2 - minute1;
            dateDiffValue = `${m > 1 ? (`${m} minutes`) : (`${m} minute`)}`;
          }
        } else {
          const mc = (60 - minute1) + minute2;
          const h = hour2 - hour1;
          if (mc < 60 && h === 1) {
            dateDiffValue = `${mc > 1 ? (`${mc} minutes`) : (`${mc} minute`)}`;
          } else {
            dateDiffValue = `${h > 1 ? (`${h} hours`) : (`${h} hour`)}`;
          }
        }
      } else {
        const d = day2 - day1;
        dateDiffValue = `${d > 1 ? (`${d} days`) : (`${d} day`)}`;
      }
    } else {
      const m = month2 - month1;
      dateDiffValue = `${m > 1 ? (`${m} months`) : (`${m} month`)}`;
    }
  }

  return `${dateDiffValue === 'now' ? dateDiffValue : (`${dateDiffValue} ago`)}`;
};

export const onDateDDMMMYYYYHHMM = (d) => {
  const dt = String(new Date(d)).split(' ');
  const h = new Date(d).getHours();
  const m = new Date(d).getMinutes();
  const mode = h > 12 ? `${h - 12 === 0 ? 12 : h - 12 >= 10 ? h - 12 : `0${h - 12}`}:${m >= 10 ? m : `0${m}`}PM` : `${h >= 10 ? h : `0${h}`}:${m >= 10 ? m : `0${m}`}AM`;
  return `${dt[2]} ${dt[1]} ${dt[3]} ${mode}`;
};

export const EU_TIMEZONES = [
  'Europe/Vienna',
  'Europe/Brussels',
  'Europe/Sofia',
  'Europe/Zagreb',
  'Asia/Famagusta',
  'Asia/Nicosia',
  'Europe/Prague',
  'Europe/Copenhagen',
  'Europe/Tallinn',
  'Europe/Helsinki',
  'Europe/Paris',
  'Europe/Berlin',
  'Europe/Busingen',
  'Europe/Athens',
  'Europe/Budapest',
  'Europe/Dublin',
  'Europe/Rome',
  'Europe/Riga',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Malta',
  'Europe/Amsterdam',
  'Europe/Warsaw',
  'Atlantic/Azores',
  'Atlantic/Madeira',
  'Europe/Lisbon',
  'Europe/Bucharest',
  'Europe/Bratislava',
  'Europe/Ljubljana',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Madrid',
  'Europe/Stockholm',
];

export const isConsentRequired = () => {
  dayjs.extend(timezone);
  return EU_TIMEZONES.includes(dayjs.tz.guess());
};
