// add reducers to store
import { combineReducers } from 'redux'
import linkReducer from './reducer/'

const allReducers = combineReducers({
  link: linkReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    localStorage.removeItem('state')
    return allReducers(undefined, action)
  }
  return allReducers(state, action)
}

export default rootReducer
