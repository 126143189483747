import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LoadingSpinner from "../../components/LoadingSpinner";
import Navbar from "../../components/Navbar";
import {
  BsFillPersonFill,
  BsWatch,
  BsPlayBtn,
  BsImageFill,
  BsFillFileTextFill,
} from "react-icons/bs";
import ReactPaginate from "react-paginate";

import no_content from "../../assets/images/no_content.svg";
import { linkActions } from "../../app/store/actions/";
import {
  getUserIdentity,
  dateCalculation,
  success,
} from "../../app/utilities/helpers/";

import "./Landing.css";

const Landing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { links, loading, identity } = useSelector((state) => state.link);
  const [currentPage, setCurrentPage] = useState(0);

  const videoExt = ["mp4"];
  const imageExt = ["jpeg", "jpg", "png"];
  const documentExt = ["pdf", "ppt", "pptx", "doc", "docx"];

  useEffect(() => {
    handleGetIP();
    window.addEventListener("unload", (e) => {
      localStorage.removeItem("state");
      dispatch(success(null, "CLEAR_STORE"));
    });
    return () => {
      window.removeEventListener("unload", (event) => {});
    };
  }, []); // eslint-disable-line

  // get composed emails and shared links by ip if !contact
  useEffect(() => {
    if (identity && identity?.ip) {
      dispatch(linkActions.handleGetLinks({ ip: identity.ip, page: 1 }));
    }
  }, [identity]); // eslint-disable-line

  const handleGetIP = async () => {
    const userIdentity = await getUserIdentity();
    userIdentity["ip"] = userIdentity["IPv4"];
    return dispatch(linkActions.handleSaveIdentity(userIdentity));
  };

  const changePage = ({ selected }) => {
    setCurrentPage(selected);
    return dispatch(
      linkActions.handleGetLinks({ ip: identity.ip, page: selected + 1 })
    );
  };

  const viewLink = (linkId) => {
    navigate(linkId);
  };

  const paginationPageCount = (totalItems) => Math.ceil(totalItems / 10);

  return (
    <>
      <Navbar />
      <div className="mt-5 pb-10 relative w-full min-h-screen relative bg-white">
        {links && links.results && links.results.length ? (
          <>
            <div className="grid grid-cols-4 gap-4 relative">
              {links.results.map((link, idx) => (
                <div
                  className="rounded-3xl bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] shadow w-full m-2 h-[200px] relative"
                  key={idx}
                  style={{
                    backgroundImage: link?.asset?.thumbnail
                      ? `url(${link?.asset.thumbnail})`
                      : link?.asset?.file
                      ? `url(${link?.asset.file})`
                      : "",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  {!link?.asset?.thumbnail &&
                  link?.asset?.file &&
                  link?.asset?.file.split(".").at(-1).includes(".mp4") ? (
                    <video
                      className="absolute w-full h-full rounded-2xl"
                      style={{ objectFit: "fill" }}
                      controls={false}
                      loop
                      muted
                      autoPlay
                    >
                      <source src={link?.asset?.file} type="video/mp4" />
                      <source src={link?.asset?.file} type="video/ogg" />
                      Your browser does not support the video tag.
                    </video>
                  ) : null}

                  <div
                    className="flex flex-col justify-center pl-8 cursor-pointer z-10 h-[200px]"
                    style={{
                      background:
                        "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QgdBy0F1sAe4QAAACFJREFUCNdj/P//vy8DBNgzMDAwMDGgARaYDAMDw0GsKgD/0gSTo/gcFwAAAABJRU5ErkJggg==) repeat hsla(0,0%,100%,.4)",
                    }}
                  >
                    <div className="font-bold text-uberark-orange truncate mt-2">
                      {link?.asset?.subject
                        ? link?.asset?.subject
                        : link?.asset?.name
                        ? link?.asset?.name.split(".").slice(0, 1)
                        : "Link"}
                    </div>
                    <div className="flex flex-col mt-2">
                      <div className="truncate text-xs text-slate-500 italic flex items-center">
                        <BsFillPersonFill className="mr-1" />{" "}
                        {link?.to_email[0]}
                      </div>
                      <div className="truncate text-xs text-slate-600 italic flex items-center">
                        <BsWatch className="mr-1" />{" "}
                        {dateCalculation(
                          new Date(link?.updated_at),
                          new Date()
                        )}
                      </div>
                      {link?.asset?.name && (
                        <div className="flex text-sm items-center uppercase">
                          {videoExt.includes(
                            link?.asset?.name.split(".").at(-1)
                          ) && (
                            <>
                              <BsPlayBtn className="mr-1" />{" "}
                              {link?.asset?.name.split(".").at(-1)} File{" "}
                            </>
                          )}
                          {imageExt.includes(
                            link?.asset?.name.split(".").at(-1)
                          ) && (
                            <>
                              <BsImageFill className="mr-1" />{" "}
                              {link?.asset?.name.split(".").at(-1)} File{" "}
                            </>
                          )}
                          {documentExt.includes(
                            link?.asset?.name.split(".").at(-1)
                          ) && (
                            <>
                              <BsFillFileTextFill className="mr-1" />{" "}
                              {link?.asset?.name.split(".").at(-1)} File{" "}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    <button
                      type="button"
                      onClick={() => viewLink(link?.asset?.link)}
                      style={{ width: "fit-content" }}
                      className="bg-[#000080] mt-4 hover:bg-white text-white hover:text-[#000080] hover:drop-shadow-lg font-bold py-1 px-2 hover:scale-105 ease-in-out duration-200 rounded"
                    >
                      Explore
                    </button>
                  </div>
                </div>
              ))}
            </div>
            {paginationPageCount(links.count) > 1 ? (
              <div className="py-4">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={paginationPageCount(links.count)}
                  onPageChange={changePage}
                  marginPagesDisplayed={0}
                  pageRangeDisplayed={5}
                  forcePage={currentPage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            ) : null}
          </>
        ) : (
          <div className="flex flex-col items-center justify-center h-[300px] w-full mt-50">
            <div>
              <img src={no_content} alt="lost" className="h-[200px]" />
            </div>
            {loading ? (
              <>
                <div className="mt-2 items-center">
                  <LoadingSpinner colorClassName="border-blue-400" />
                </div>
                <div className="mt-2 text-slate-600 text-sm font-semibold">
                  Loading...
                </div>
              </>
            ) : (
              <>
                <div className="mt-2 text-slate-600 text-sm font-semibold">
                  It's cold out here ❄️
                </div>
                <div className="mt-2 text-slate-600 text-xs">
                  Shared content will be displayed here.
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Landing;
