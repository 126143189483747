import React from 'react'

import Horizontal from './Horizontal'
import Vertical from './Vertical'

const Layout = ({ linkData, layout, collectionData, openSection, handleSaveEvent, activeAsset, handleOnAssetClick, handleSectionChange, pageViewer }) => {
  return (
    <>
    {(layout === 'horizontal' && collectionData && collectionData.id) ? (
      <Horizontal
        openSection={openSection}
        handleSaveEvent={handleSaveEvent}
        collectionData={collectionData}
        activeAsset={activeAsset}
        handleOnAssetClick={handleOnAssetClick}
        handleSectionChange={handleSectionChange} />
    ) : (
      <Vertical
        linkData={linkData}
        openSection={openSection}
        handleSaveEvent={handleSaveEvent}
        collectionData={collectionData}
        activeAsset={activeAsset}
        handleOnAssetClick={handleOnAssetClick}
        handleSectionChange={handleSectionChange}
        pageViewer={pageViewer} />
    )}
    </>
  )
}

export default Layout
