import React, { useState } from "react";
import { useSelector } from "react-redux";

import File from "../../../../../../components/File";
import VerticalSidebar from "./VerticalSidebar";
import Compose from "../../../Compose/";
import LoadingSpinner from "../../../../../../components/LoadingSpinner";
import { CgSoftwareDownload, CgShare } from "react-icons/cg";

import no_content from "../../../../../../assets/images/no_content.svg";
import logo from "../../../../../../assets/images/logos/logo.svg";

const Vertical = ({
  linkData,
  handleSaveEvent,
  collectionData,
  openSection,
  activeAsset,
  handleOnAssetClick,
  handleSectionChange,
  pageViewer,
}) => {
  const { loadingSession } = useSelector((state) => state.link);
  const [share, setShare] = useState(false);
  const handleGetReadableTimeRange = (timeSpent) => {
    let readableTime;
    if (timeSpent < 60) {
      readableTime = timeSpent + " seconds";
    } else if (timeSpent >= 3600 && timeSpent < 216000) {
      const minutes = Math.floor(timeSpent / 60);
      const seconds = Math.floor(timeSpent % 60);
      readableTime = `${minutes}min${minutes === 1 ? "" : "s"} ${seconds}sec${
        seconds === 0 || seconds === 1 ? "" : "s"
      }`;
    } else if (timeSpent >= 216000) {
      const hours = Math.floor(timeSpent / 3600);
      const minutes = Math.floor((timeSpent % 3600) / 60);
      readableTime = `${hours}hr${hours === 1 ? "" : "s"} ${minutes}min${
        minutes === 1 ? "" : "s"
      }`;
    }

    return readableTime;
  };

  const onShareClose = () => {
    setShare(false);
  };

  const units = [
    "bytes",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];

  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  }

  return (
    <div className="h-screen pb-20">
      <div className="w-full">
        <div className="flex flex-wrap">
          <div
            className={`${
              collectionData && collectionData.id ? "" : "justify-center"
            } flex w-full`}
          >
            {/* sidebar ---------------------------------------------------------- */}
            {collectionData && collectionData.id && (
              <VerticalSidebar
                collectionData={collectionData}
                openSection={openSection}
                activeAsset={activeAsset}
                handleOnAssetClick={handleOnAssetClick}
                handleSectionChange={handleSectionChange}
              />
            )}

            {/* assets ---------------------------------------------------------- */}
            <div className="w-10/12 h-screen bg-white">
              {activeAsset ? (
                <div className="w-full h-full mb-5 px-10">
                  <div className="flex justify-between px-5 pt-5">
                    <div className="text-xs flex flex-wrap items-center text-sm divide-x text-slate-600 divide-slate-500 w-full">
                      {activeAsset?.file_size && (
                        <div className="px-2">
                          {niceBytes(activeAsset.file_size)}
                        </div>
                      )}
                      {activeAsset?.name && (
                        <div className="px-2">
                          {activeAsset.url
                            ? activeAsset?.name || activeAsset?.url
                            : activeAsset?.name.split(".").pop()}
                        </div>
                      )}
                      {activeAsset?.page_count &&
                        activeAsset?.video_duration !== "0" && (
                          <div className="px-2">
                            {activeAsset.page_count} pages
                          </div>
                        )}
                      {activeAsset?.video_duration &&
                        activeAsset?.video_duration !== "0" && (
                          <div className="px-2">
                            {handleGetReadableTimeRange(
                              activeAsset.video_duration
                            )}
                          </div>
                        )}
                      <div className="px-2">
                        {activeAsset.url ? "URL" : "Uberark Cloud"}
                      </div>
                    </div>
                    <div className="flex items-center">
                      {linkData.share && (
                        <button
                          onClick={() => setShare(true)}
                          className="flex justify-center items-center mr-1"
                        >
                          <CgShare className="text-[#FE4502] font-bold" /> Share
                        </button>
                      )}
                      {linkData.download && (
                        <button
                          onClick={() =>
                            handleSaveEvent({ eventName: "download" })
                          }
                          className="flex justify-center items-center"
                        >
                          <CgSoftwareDownload className="text-[#FE4502] font-bold" />{" "}
                          Download
                        </button>
                      )}
                    </div>
                  </div>

                  <File
                    file={activeAsset.file}
                    name={activeAsset.name}
                    url={activeAsset?.url || null}
                    handleSaveEvent={handleSaveEvent}
                    containerClassName="p-1 m-2 file-card__lg relative w-full"
                    unsupportedFileClassName="file-unsupported__lg"
                  />
                </div>
              ) : (
                <>
                  {loadingSession ? (
                    <div className="flex flex-col items-center justify-center h-[60vh] w-full">
                      <div>
                        <img
                          src={logo}
                          alt="lost"
                          className="h-[120px] w-[120px] animate-pulse"
                        />
                      </div>
                      <div className="mt-2 items-center">
                        <LoadingSpinner colorClassName="border-blue-400" />
                      </div>
                      <div className="mt-2 text-slate-600 text-sm font-semibold">
                        Loading
                      </div>
                      <div className="mt-2 text-slate-600 text-xs">
                        Just a moment. You are almost there.
                      </div>
                    </div>
                  ) : (
                    <div className="h-[200px] w-full flex flex-col justify-center items-center my-10">
                      <div className="w-full flex justify-center">
                        <img
                          src={no_content}
                          alt="nothing to show illustration"
                          style={{ height: "60px" }}
                        />
                      </div>
                      <div className="mt-2 text-slate-600 text-sm font-semibold">
                        It's cold out here ❄️
                      </div>
                      <div className="mt-2 text-slate-600 text-xs">
                        Shared asset will be displayed here.
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          {share && (
            <div
              onClick={onShareClose}
              className="pop_up_container fixed inset-0 bg-gray-50 bg-opacity-30 backdrop-blur-sm text-black h-full"
              style={{ zIndex: "100", overflowY: "scroll" }}
            >
              <div onClick={(e) => e.stopPropagation()} className="h-full flex">
                <div className="bg-white pl-5 pt-5 md:w-4/6 w-full">
                  <Compose
                    onShareClose={onShareClose}
                    handleSaveEvent={handleSaveEvent}
                    assets={[activeAsset]}
                    pageViewer={pageViewer}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Vertical;
