import React from 'react';

import Navbar from '../../components/Navbar';

import access from '../../assets/images/access.svg';


const Access = () => {
  return (
    <>
    <Navbar />
    <div className="mt-6 md:mt-0 flex flex w-full h-screen relative top-20 md:top-0 bg-white">
      <div className="flex flex-col items-center justify-center ml-3 mt-4 h-[200px] w-full mt-[70px]">
        <div><img src={access} alt="lost" className="h-[200px]"/></div>
        <div className="mt-2 text-slate-600 text-sm font-semibold">Permision Denied!</div>
        <div className="mt-2 text-slate-600 text-xs">You dont have access to link.</div>
      </div>
    </div>
    </>
  );
};

export default Access;
