import React from "react";
import { useSelector } from "react-redux";

import avatarPlaceholder from "../../../../../../assets/images/avatar-placeholder.png";

const HorizontalHead = ({ collectionData }) => {
  const { linkData } = useSelector((state) => state.link);

  return (
    <div
      className="relative min-h-[370px] bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] shadow object-contain flex flex-wrap text-gray-600"
      style={{
        backgroundImage: collectionData?.thumbnail
          ? `url(${collectionData.thumbnail})`
          : collectionData?.banner
          ? `url(${collectionData.banner})`
          : "",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {collectionData?.banner &&
      collectionData.banner.split(".").at(-1).includes("video/mp4") ? (
        <video
          className="absolute w-full h-full rounded-2xl"
          style={{ objectFit: "fill" }}
          controls={false}
          loop
          muted
          autoPlay
        >
          <source src={collectionData?.banner} type="video/mp4" />
          <source src={collectionData?.banner} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      ) : null}

      <div
        className="relative p-8 pt-2 justify-between w-full relative bg-[#fafafd4f]"
        style={{
          background:
            "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QgdBy0F1sAe4QAAACFJREFUCNdj/P//vy8DBNgzMDAwMDGgARaYDAMDw0GsKgD/0gSTo/gcFwAAAABJRU5ErkJggg==) repeat hsla(0,0%,100%,.4)",
        }}
      >
        <div className="flex flex-col items-center md:flex-row">
          <div className="flex sm:flex-row flex-col pt-2 gap-4 min-h-[166px] md:w-3/4 w-full pr-2">
            <div className="flex flex-col gap-2">
              <span className="text-[#FE4502] font-bold text-2xl text-left mb-2">
                {collectionData?.name}
              </span>
              <span
                className="e-content"
                dangerouslySetInnerHTML={{
                  __html: collectionData?.description,
                }}
              />
            </div>
          </div>

          <div className="flex flex-col flex-wrap h-full md:w-1/4 w-full">
            <div className="flex items-center md:w-[160px] xl:w-[220px]">
              <div className="flex flex-row items-center gap-4 hover:scale-105 ease-in-out duration-200">
                <span className="flex text-14 font-semibold text-uberark-blue">
                  Shared By:
                </span>
              </div>
            </div>
            <div className="flex items-center mt-2 md:w-[160px] xl:w-[220px]">
              <img
                src={
                  linkData?.user?.avatar
                    ? linkData.user.avatar
                    : avatarPlaceholder
                }
                alt="avatar"
                className="rounded-full w-[30px] h-[30px] hover:bg-light-gray object-cover mr-1"
                style={{ border: "0.24px solid #000080" }}
              />

              <div className="md:w-[130px] xl:w-[190px]">
                <div className="truncate text-sm">
                  {linkData?.user?.username}
                </div>
                <div className="truncate text-sm">{linkData?.user?.email}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalHead;
