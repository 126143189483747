import React, { useEffect } from "react";

import { BsFileEarmark, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { BiLink } from "react-icons/bi";
import "./File.css";

const File = ({
  file,
  name,
  url,
  containerClassName,
  unsupportedFileClassName,
  handleSaveEvent,
  thumbnail,
}) => {
  const imageExtensions = ["jpeg", "jpg", "png"];
  const videoExtensions = ["mp4"];
  const iframeCompatibleDocuments = [
    "pdf",
    "ppt",
    "pptx",
    "doc",
    "docx",
    "xls",
    "xlxs",
  ];
  const allFilesSupported =
    imageExtensions + videoExtensions + iframeCompatibleDocuments;

  useEffect(() => {
    if (
      containerClassName.includes("file-card__lg") &&
      name.split(".").at(-1) === "pdf"
    ) {
      setTimeout(function () {
        // If absolute URL from the remote server is provided, configure the CORS
        // header on that server.
        var url = file;

        // Loaded via <script> tag, create shortcut to access PDF.js exports.
        var pdfjsLib = window["pdfjs-dist/build/pdf"];

        // The workerSrc property shall be specified.
        pdfjsLib.GlobalWorkerOptions.workerSrc =
          "//mozilla.github.io/pdf.js/build/pdf.worker.js";

        let pdfDoc = null;
        let pageNum = 1;
        let pageRendering = false;
        let pageNumPending = null;
        const scale = 0.9;
        const canvas = document.getElementById("the-canvas");
        const ctx = canvas?.getContext("2d", { alpha: false }) || null;

        /**
         * Get page info from document, resize canvas accordingly, and render page.
         * @param num Page number.
         */
        function renderPage(num) {
          pageRendering = true;
          // Using promise to fetch the page
          pdfDoc.getPage(num).then(function (page) {
            var viewport = page.getViewport({ scale: scale });
            // canvas.height = viewport.height;
            // canvas.width = viewport.width;

            // Get the DPR and size of the canvas
            const dpr = window.devicePixelRatio;
            // const rect = canvas.getBoundingClientRect();
            // Set the "actual" size of the canvas
            // canvas.width = rect.width * dpr;
            // canvas.height = rect.height * dpr;

            // Set the "actual" size of the canvas
            canvas.width = viewport.width * dpr;
            canvas.height = viewport.height * dpr;

            // Scale the context to ensure correct drawing operations
            ctx.scale(dpr, dpr);

            // Set the "drawn" size of the canvas
            canvas.style.width = `${viewport.width}px`;
            canvas.style.height = `${viewport.height}px`;

            // Render PDF page into canvas context
            var renderContext = {
              canvasContext: ctx,
              viewport: viewport,
            };
            var renderTask = page.render(renderContext);

            // Wait for rendering to finish
            renderTask.promise.then(function () {
              pageRendering = false;
              if (pageNumPending !== null) {
                // New page rendering is pending
                renderPage(pageNumPending);
                pageNumPending = null;
              }
            });
          });

          // Update page counters
          if (document.getElementById("page_num")) {
            document.getElementById("page_num").textContent = num;
          }
        }

        /**
         * If another page rendering in progress, waits until the rendering is
         * finised. Otherwise, executes rendering immediately.
         */
        function queueRenderPage(num) {
          if (pageRendering) {
            pageNumPending = num;
          } else {
            renderPage(num);
          }
        }

        /**
         * Displays previous page.
         */
        function onPrevPage() {
          if (pageNum <= 1) {
            return;
          }
          pageNum--;
          queueRenderPage(pageNum);
        }
        if (document.getElementById("prev")) {
          document.getElementById("prev").addEventListener("click", onPrevPage);
        }

        /**
         * Displays next page.
         */
        function onNextPage() {
          if (pageNum >= pdfDoc.numPages) {
            return;
          }
          pageNum++;
          queueRenderPage(pageNum);
        }
        if (document.getElementById("next")) {
          document.getElementById("next").addEventListener("click", onNextPage);
        }

        /**
         * Asynchronously downloads PDF.
         */
        pdfjsLib.getDocument(url).promise.then(function (pdfDoc_) {
          pdfDoc = pdfDoc_;
          if (document.getElementById("page_count")) {
            document.getElementById("page_count").textContent = pdfDoc.numPages;
          }

          // Initial/first page rendering
          renderPage(pageNum);
        });
      }, 1000);
    }
  }, [containerClassName, name, file]);

  return (
    <div className={containerClassName}>
      {thumbnail ? (
        <img src={file} alt="file" className="rounded-sm h-5/6" />
      ) : (
        <>
          {iframeCompatibleDocuments.includes(name.split(".").at(-1)) && (
            <>
              {containerClassName.includes("file-card__lg") &&
              name.split(".").at(-1) === "pdf" ? (
                <div className="flex flex-col flex-wrap w-full bg-white px-5 rounded">
                  <div className="flex justify-between mb-2 text-white bg-black fixed bottom-2 rounded py-2">
                    <button
                      id="prev"
                      onClick={() =>
                        handleSaveEvent &&
                        handleSaveEvent({ eventName: "document_page_change" })
                      }
                      className="flex items-center text-xs px-1 mx-2"
                    >
                      <BsChevronLeft />
                    </button>
                    <div className="text-xs mx-2">
                      Page: <span id="page_num"></span> /{" "}
                      <span id="page_count"></span>
                    </div>
                    <button
                      id="next"
                      onClick={() =>
                        handleSaveEvent &&
                        handleSaveEvent({ eventName: "document_page_change" })
                      }
                      className="flex items-center text-xs px-1 mx-2"
                    >
                      <BsChevronRight />
                    </button>
                  </div>
                  <canvas
                    id="the-canvas"
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  ></canvas>
                </div>
              ) : (
                <object
                  className="rounded-sm documentObject h-[90vh]"
                  scrolling="no"
                  id={containerClassName}
                  marginHeight="0"
                  marginWidth="0"
                  allowFullScreen={false}
                  seamless
                  loading="lazy"
                  standby="Loading..."
                  data={`https://docs.google.com/viewer?url=${encodeURIComponent(
                    file
                  )}&embedded=true&navpanes=0&scrollbar=0&toolbar=0&zoom=100`}
                  frameBorder="0"
                >
                  <p className="flex flex-col justify-center mt-5">
                    File not supported.
                    <button
                      onClick={() =>
                        handleSaveEvent &&
                        handleSaveEvent({ eventName: "download" })
                      }
                      className="text-uberark-orange mx-1"
                    >
                      Download
                    </button>
                  </p>
                </object>
              )}
            </>
          )}
          {videoExtensions.includes(name.split(".").at(-1).toLowerCase()) && (
            <video
              src={file}
              controls
              id={
                containerClassName.includes("file-card__lg")
                  ? "video-lg"
                  : "video"
              }
              className="rounded-sm h-5/6"
              onPlay={() =>
                handleSaveEvent && handleSaveEvent({ eventName: "video_play" })
              }
              onPause={(e) => {
                if (e.target.currentTime !== e.target.duration) {
                  handleSaveEvent &&
                    handleSaveEvent({ eventName: "video_pause" });
                }
              }}
              onEnded={() =>
                handleSaveEvent && handleSaveEvent({ eventName: "video_stop" })
              }
            />
          )}

          {url && (
            <div
              className={`flex flex-col items-center justify-center bg-white p-2`}
              onMouseDown={(e) => {
                if (containerClassName.includes("file-card__lg")) {
                  return window.open(url, "_blank");
                }
                return null;
              }}
            >
              <div className="">
                <BiLink className="text-uberark-orange" />
              </div>
              {containerClassName.includes("file-card__lg") && (
                <a
                  href={url}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="w-full flex flex-col text-center justify-center truncate mt-2"
                >
                  <div className="truncate">{url}</div>
                </a>
              )}
            </div>
          )}
          {imageExtensions.includes(name.split(".").at(-1)) && (
            <img src={file} alt="file" className="rounded-sm h-5/6" />
          )}
          {!url && !allFilesSupported.includes(name.split(".").at(-1)) && (
            <div
              className={`${unsupportedFileClassName} flex items-center justify-content-center rounded-sm`}
              onMouseDown={() =>
                handleSaveEvent && handleSaveEvent({ eventName: "download" })
              }
            >
              <BsFileEarmark className="text-[#000080] drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default File;
