import React from "react";

import File from "../../../../../../components/File";
import HorizontalHead from "./HorizontalHead";

import no_content from "../../../../../../assets/images/no_content.svg";

const Horizontal = ({
  openSection,
  collectionData,
  handleSaveEvent,
  activeAsset,
  handleOnAssetClick,
  handleSectionChange,
}) => {
  return (
    <>
      <HorizontalHead collectionData={collectionData} />

      <div className="bg-gradient-to-r from-[#fffcf9be] to-[#fffaf9de] mb-20 p-2 pb-20 shadow">
        {/*sections -----------------------------------------------------------------------------------------------*/}
        <div className="grid grid-flow-col md:grid-flow-col justify-center mb-10 text-sm divide-x text-slate-600 divide-slate-500">
          {collectionData?.section &&
            collectionData?.section.length > 1 &&
            collectionData?.section.map((item, index) => (
              <div
                key={index}
                className={`px-2 font-bold ${
                  openSection.id === item.id
                    ? "text-uberark-orange"
                    : "text-uberark-blue"
                }`}
                onMouseDown={() => handleSectionChange(item)}
              >
                {item.name}
              </div>
            ))}
        </div>

        {/* assets ------------------------------------------------------------------------------------------------*/}
        <div className="w-full">
          <div className="mt-8">
            {openSection?.asset && openSection?.asset.length > 0 ? (
              <div className="flex flex-wrap">
                {openSection.asset.map((asset) => (
                  <div
                    className="w-[150px] flex flex-col"
                    onMouseDown={() => handleOnAssetClick(asset)}
                    key={asset.id}
                  >
                    <File
                      file={asset.thumbnail ? asset.thumbnail : asset.file}
                      thumbnail={asset?.thumbnail || null}
                      name={asset.name}
                      url={asset.url || null}
                      handleSaveEvent={handleSaveEvent}
                      containerClassName="p-1 m-2 file-card__sm relative w-full"
                      unsupportedFileClassName="file-unsupported__sm"
                    />
                    <div className="file-card__sm-name truncate">
                      {asset.name || asset.url}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="h-[155px] w-full flex flex-col justify-center items-center mb-10">
                <div className="w-full flex justify-center">
                  <img
                    src={no_content}
                    alt="nothing to show illustration"
                    style={{ height: "60px" }}
                  />
                </div>
                <div className="text-center relative p-5 text-[#757575] text-14">
                  Nothing to show.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Horizontal;
