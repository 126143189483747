import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { BsChevronLeft, BsStack } from "react-icons/bs";
import { success } from "../app/utilities/helpers/";
import logo from "../assets/images/logos/logo.svg";
import "./navbar.css";

const Navbar = ({
  handleSaveEvent,
  activeAsset,
  setActiveAsset,
  layout,
  handleBack,
  collectionData,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    if (collectionData && collectionData?.id) {
      setLogoUrl(collectionData.logo);
    }
  }, [collectionData]);

  const handleViewLibrary = () => {
    // trigger link_close event
    activeAsset && handleSaveEvent({ eventName: "view_stop" });
    setActiveAsset && setActiveAsset();
    setTimeout(function () {
      handleSaveEvent && handleSaveEvent({ eventName: "link_close" });
      dispatch(success(null, "CLEAR_STORE"));
    }, 100);

    setTimeout(function () {
      navigate(`/`);
    }, 500);
  };

  return (
    <div
      className={`bg-white shadow flex justify-between items-center p-2 relative border-b`}
    >
      <div className="flex items-center gap-4 ml-2">
        {layout === "vertical" && collectionData && collectionData.id && (
          <button
            type="button"
            className={`flex items-center mt-4`}
            onClick={() => handleBack("horizontal")}
          >
            <div className="flex items-center">
              <BsChevronLeft />
            </div>
            <div className="p-0 ml-1">Back</div>
          </button>
        )}
        <div className="flex flex-col lg:items-center">
          <div className="flex items-center mt-4 text-xl font-extrabold tracking-tight text-slate-900">
            <img
              src={logoUrl || logo}
              alt="logo"
              style={{
                height: "30px",
                maxWidth: "100px",
                objectFit: "contain",
              }}
            />
            {!logoUrl && <span>Uberark</span>}
          </div>
        </div>
      </div>
      <div>
        {activeAsset && (
          <div className="text-xl font-medium text-slate-900 truncate">
            {activeAsset.url
              ? activeAsset?.name || activeAsset?.url
              : activeAsset?.name.split(".").slice(0, 1)}
          </div>
        )}
      </div>
      {location.pathname !== "/" && (
        <div
          className="flex font-medium text-slate-900"
          onMouseDown={() => handleViewLibrary()}
        >
          <BsStack className="mr-4" />
        </div>
      )}
    </div>
  );
};

export default Navbar;
