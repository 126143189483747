import React from "react";
import { useSelector } from "react-redux";

import {
  BsArrowRightShort,
  BsPlayBtn,
  BsImageFill,
  BsFillFileTextFill,
  BsPlusCircle,
  BsDashCircle,
} from "react-icons/bs";
import { BiLink } from "react-icons/bi";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const VerticalSidebar = ({
  collectionData,
  openSection,
  activeAsset,
  handleOnAssetClick,
  handleSectionChange,
}) => {
  const { assetState } = useSelector((state) => state.link);

  const videoExt = ["mp4"];
  const imageExt = ["jpeg", "jpg", "png"];
  const documentExt = ["pdf", "ppt", "pptx", "doc", "docx"];

  return (
    <div className="w-2/12 relative min-h-screen bg-slate-100 shadow px-1 border-r-2 scroll-smooth hover:scroll-auto">
      {openSection &&
        collectionData.section &&
        collectionData.section.length > 1 &&
        collectionData.section.map((section) => (
          <div
            className={`${
              openSection.id === section.id
                ? "border-orange-500"
                : "border-slate-200"
            } border-l-4 py-3 px-2 text-slate-500`}
            key={section.id}
          >
            <div
              onMouseDown={() => handleSectionChange(section)}
              className={`${
                openSection.id === section.id ? "text-slate-900" : ""
              } mb-2`}
            >
              <div className="text-base font-medium flex items-center">
                <div className="mr-1 text-xs">
                  {openSection.id === section.id ? (
                    <BsDashCircle />
                  ) : (
                    <BsPlusCircle />
                  )}
                </div>
                {section.name}
              </div>
              <div className="text-xs italic pl-5">
                {section.asset.length} Asset(s)
              </div>
            </div>
            {openSection.id === section.id && (
              <div className="pl-5">
                {section.asset.map((asset) => (
                  <div
                    className={`${
                      activeAsset && activeAsset.id === asset.id
                        ? "text-slate-900 bg-slate-200"
                        : ""
                    } flex flex-col border-t py-4 rounded px-2`}
                    onMouseDown={() => handleOnAssetClick(asset)}
                    key={asset.id}
                  >
                    <div className="flex justify-between">
                      <div className="flex flex-col relative w-10/12">
                        <div className="truncate text-sm font-medium">
                          {asset.name}
                        </div>
                      </div>
                      <div className="flex justify-end items-center w-2/12">
                        <CircularProgressbar
                          value={
                            assetState && assetState.hasOwnProperty(asset.id)
                              ? assetState[asset.id]
                              : 0
                          }
                          text={`${
                            assetState && assetState.hasOwnProperty(asset.id)
                              ? assetState[asset.id]
                              : 0
                          }%`}
                          styles={buildStyles({
                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 1,
                            // Colors
                            pathColor: `#000080`,
                            textColor: `#f88`,
                          })}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between mt-2">
                      <div className="">
                        {videoExt.includes(asset.name.split(".").at(-1)) && (
                          <BsPlayBtn />
                        )}
                        {imageExt.includes(asset.name.split(".").at(-1)) && (
                          <BsImageFill />
                        )}
                        {documentExt.includes(asset.name.split(".").at(-1)) && (
                          <BsFillFileTextFill />
                        )}
                        {asset.url && <BiLink />}
                      </div>
                      {activeAsset && activeAsset.id === asset.id ? (
                        <div className="text-[10px] flex items-center text-uberark-orange">
                          NOW SHOWING <BsArrowRightShort className="text-sm" />
                        </div>
                      ) : (
                        <div className="text-[10px] flex items-center">
                          SHOW <BsArrowRightShort className="text-sm" />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}

      {collectionData.section && collectionData.section.length === 1 && (
        <>
          {collectionData.section[0].asset.map((asset) => (
            <div
              className={`${
                activeAsset && activeAsset.id === asset.id
                  ? "text-slate-900 bg-slate-200"
                  : ""
              } flex flex-col border-t py-4 rounded px-2`}
              onMouseDown={() => handleOnAssetClick(asset)}
              key={asset.id}
            >
              <div className="flex justify-between">
                <div className="flex flex-col relative w-10/12">
                  <div className="truncate text-sm font-medium">
                    {asset.name || asset.url}
                  </div>
                </div>
                <div className="flex justify-end items-center w-2/12">
                  <CircularProgressbar
                    value={
                      assetState && assetState.hasOwnProperty(asset.id)
                        ? assetState[asset.id]
                        : 0
                    }
                    text={`${
                      assetState && assetState.hasOwnProperty(asset.id)
                        ? assetState[asset.id]
                        : 0
                    }%`}
                    styles={buildStyles({
                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 1,
                      // Colors
                      pathColor: `#000080`,
                      textColor: `#f88`,
                    })}
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="">
                  {videoExt.includes(asset.name.split(".").at(-1)) && (
                    <BsPlayBtn />
                  )}
                  {imageExt.includes(asset.name.split(".").at(-1)) && (
                    <BsImageFill />
                  )}
                  {documentExt.includes(asset.name.split(".").at(-1)) && (
                    <BsFillFileTextFill />
                  )}
                  {asset.url && <BiLink />}
                </div>
                {activeAsset && activeAsset.id === asset.id ? (
                  <div className="text-xs flex items-center text-uberark-orange">
                    NOW SHOWING <BsArrowRightShort />
                  </div>
                ) : (
                  <div className="text-xs flex items-center">
                    SHOW <BsArrowRightShort />
                  </div>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default VerticalSidebar;
