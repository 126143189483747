import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { Wizard, Steps, Step } from "react-albus";
import Navbar from "../../components/Navbar";
import Password from "./components/Password";
import Expiry from "./components/Expiry";
import Email from "./components/Email";
import NDA from "./components/NDA";
import Collection from "./components/Collection/";
import LoadingSpinner from "../../components/LoadingSpinner";

import {
  handleGenerateEventPayload,
  getUserIdentity,
  success,
} from "../../app/utilities/helpers/";
import useTabActive from "../../app/utilities/hooks/useTabActive";
import { linkActions } from "../../app/store/actions/";
import logo from "../../assets/images/logos/logo.svg";
import LoadingLinear from "../../components/LoadingLinear";
import Privacy from "./components/Privacy";

const View = () => {
  let { token: id } = useParams();
  const tabActive = useTabActive();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { linkData, contact, loading, session, identity, progressLoader } =
    useSelector((state) => state.link);

  const [pageViewer, setPageViewer] = useState();
  const [activeAsset, setActiveAsset] = useState();
  const [layout, setLayout] = useState("vertical");
  const [linkId, setLinkId] = useState(id);
  const [collectionData, setCollectionData] = useState({});
  const [openSection, setOpenSection] = useState();

  useEffect(() => {
    // page open listener
    dispatch(success(null, "CLEAR_STORE"));
    localStorage.removeItem("state");
    handleGetIP();
    setPageViewer();
    setActiveAsset();
    setLayout("vertical");
    setLinkId(id);
    setCollectionData({});
    setOpenSection();

    // get link data
    if (id) {
      dispatch(linkActions.handleGetLinkData(linkId));
    }
  }, []); // eslint-disable-line

  // page close listener
  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      e.preventDefault();
      activeAsset && handleSaveEvent({ eventName: "view_stop" });
      activeAsset && handleSaveEvent({ eventName: "link_close" });
      for (var i = 0; i < 100000; i++) {
        console.log(i);
      }
      return true;
    });
    return () => {
      window.removeEventListener("beforeunload", (event) => {});
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    window.addEventListener("unload", (e) => {
      dispatch(success(null, "CLEAR_STORE"));
      localStorage.removeItem("state");
      for (var i = 0; i < 2000; i++) {
        console.log(i);
      }
      return true;
    });
    return () => {
      window.removeEventListener("unload", (event) => {});
    };
  }, []); // eslint-disable-line

  // tab open/close listener
  useEffect(() => {
    if (tabActive) {
      // viewing tab
      activeAsset && handleSaveEvent({ eventName: "view_start" });
    } else {
      // leaving tab
      handleSaveEvent({ eventName: "view_stop" });
    }
  }, [tabActive]); // eslint-disable-line

  // get page viewer details
  useEffect(() => {
    if (linkData && linkData.id) {
      if (
        linkData.contact_data &&
        linkData.contact_data.length &&
        !linkData.email_gate
      ) {
        setPageViewer(linkData.contact_data[0].id);
      } else if (
        linkData.contact_data &&
        linkData.contact_data.length === 0 &&
        !linkData.email_gate
      ) {
        // user is probably not authorized
        navigate(`/unauthorized`);
      } else if (contact && contact.id) {
        setPageViewer(contact.id);
      }

      if (linkData?.deleted || (linkData?.email && linkData?.email?.deleted)) {
        navigate(`/notfound`);
      }
    }
  }, [contact, linkData]); // eslint-disable-line

  // create session
  useEffect(() => {
    if (activeAsset) {
      const imageExtensions = [
        "jpeg",
        "jpg",
        "png",
        "ppt",
        "pptx",
        "doc",
        "docx",
        "xls",
        "xlxs",
      ];
      if (imageExtensions.includes(activeAsset.name.split(".").at(-1))) {
        dispatch(linkActions.handleSaveAssetState({ [activeAsset.id]: 100 }));
      }

      if (activeAsset.url) {
        dispatch(linkActions.handleSaveAssetState({ [activeAsset.id]: 100 }));
      }

      // create session
      if (linkData.all_asset_links && linkData.all_asset_links.length) {
        const assetLink = linkData.all_asset_links.filter(
          (link) => link.asset_id === activeAsset.id
        )[0].link_id;
        setLinkId(assetLink);
        dispatch(
          linkActions.handleCreateSession({
            link: assetLink,
            ip: identity?.IPv4 || null,
            os: identity?.os || null,
            browser: identity?.browser || null,
            city: identity?.city || null,
            country_code: identity?.country_code || null,
            country_name: identity?.country_name || null,
            contact: pageViewer || null,
          })
        );
      }
    }
  }, [activeAsset, dispatch]); // eslint-disable-line

  // link_open/view_start event
  useEffect(() => {
    if (session && activeAsset) {
      handleSaveEvent({ eventName: "link_open" });
      setTimeout(() => {
        handleSaveEvent({ eventName: "view_start", asset: activeAsset });
      }, 500);
    }
  }, [session]); // eslint-disable-line

  // get collection data
  useEffect(() => {
    if (linkData?.collection && linkData?.collection.id) {
      let collectionDataRef = linkData.collection;
      if (linkData.sections_accessible) {
        collectionDataRef.section = collectionDataRef.section.filter((item) =>
          linkData.sections_accessible.includes(item.id)
        );
      }
      collectionDataRef.section.map((item, index_section) => {
        const assets = item.asset.filter(
          (asset_item) =>
            linkData.all_asset_links.filter(
              (link_item) => link_item.asset_id === asset_item.id
            ).length > 0
        );
        collectionDataRef.section[index_section].asset = assets;
        return true;
      });
      setCollectionData(collectionDataRef);

      if (linkData?.asset_data && linkData?.asset_data.length) {
        // get asset section
        const newOpenSection = linkData.collection.section.filter(
          (section) => section.id === linkData?.asset_data[0].section
        )[0];
        setOpenSection(newOpenSection);
        setActiveAsset(linkData.asset_data[0]);

        // link is probably an asset link
        setLayout("vertical");
      } else {
        if (linkData.collection.section && linkData.collection.section.length) {
          setOpenSection(linkData.collection.section[0]);
        }

        // link to a collection
        setLayout("horizontal");
      }
    } else if (linkData?.asset_data && linkData?.asset_data.length) {
      setActiveAsset(linkData.asset_data[0]);

      // link is probably an asset link
      setLayout("vertical");
    }
  }, [linkData]); // eslint-disable-line

  const handleSectionChange = (section) => {
    setOpenSection(section);

    if (layout === "vertical") {
      if (activeAsset) {
        handleSaveEvent({ eventName: "view_stop" });
      }

      // update active asset to the new section's first asset
      let newActiveAsset = null;
      if (section.asset.length) {
        newActiveAsset = section.asset[0];
      }
      setActiveAsset(newActiveAsset);
    }
  };

  const handleGetIP = async () => {
    const userIdentity = await getUserIdentity();
    return dispatch(linkActions.handleSaveIdentity(userIdentity));
  };

  const handleSaveEvent = (args) => {
    const data = args;
    // get asset link_id
    data.linkId = linkId;

    if (!data.asset) {
      data.asset = activeAsset;
    }

    const payload = handleGenerateEventPayload(data);
    // user viewing details
    if (session?.id) {
      payload.session = session.id;
    }
    // user viewing details
    if (pageViewer) {
      payload.contact = pageViewer;
    }
    if (identity && identity?.IPv4) {
      payload.ip = identity.IPv4;
    }

    dispatch(linkActions.handleSaveEvent(payload));

    if (
      data?.asset?.name &&
      data.asset.name.split(".").at(-1) === "pdf" &&
      args.eventName === "document_page_change"
    ) {
      if (payload.document_page === parseInt(data.asset.page_count)) {
        const percentage =
          (parseInt(payload.document_page) / parseInt(data.asset.page_count)) *
          100;
        dispatch(
          linkActions.handleSaveAssetState({ [data.asset.id]: percentage })
        );
      }
    }

    if (data?.asset?.name && data.asset.name.split(".").at(-1) === "mp4") {
      if (args.eventName === "video_stop") {
        dispatch(linkActions.handleSaveAssetState({ [data.asset.id]: 100 }));
      }
      if (args.eventName === "video_pause") {
        const video_duration = data.asset.video_duration;
        if (video_duration && payload.video_point) {
          const percentage = Math.round(
            (parseFloat(payload.video_point) / parseFloat(video_duration)) * 100
          );
          dispatch(
            linkActions.handleSaveAssetState({ [data.asset.id]: percentage })
          );
        }
      }
    }
  };

  const handleBack = () => {
    setLayout("horizontal");
    if (activeAsset) {
      handleSaveEvent({ eventName: "view_stop" });
    }
    setActiveAsset();
  };

  return (
    <>
      {progressLoader ? (
        <LoadingLinear LoadingProgressColor={"#FE4502"} />
      ) : null}
      <Navbar
        handleSaveEvent={handleSaveEvent}
        activeAsset={activeAsset}
        setActiveAsset={setActiveAsset}
        layout={layout}
        handleBack={handleBack}
        collectionData={collectionData}
      />
      {loading && !(linkData && linkData.id) ? (
        <div className="flex flex-col items-center justify-center h-[300px] w-full pt-100">
          <div>
            <img
              src={logo}
              alt="logo"
              className="h-[120px] w-[120px] animate-pulse"
            />
          </div>
          <div className="mt-2 items-center">
            <LoadingSpinner colorClassName="border-blue-400" />
          </div>
          <div className="mt-2 text-slate-600 text-sm font-semibold">
            Loading
          </div>
          <div className="mt-2 text-slate-600 text-xs">
            Just a moment. You are almost there.
          </div>
        </div>
      ) : (
        <>
          <Wizard>
            <Steps>
              <Step
                id="Expiry"
                render={({ next }) => (
                  <div>
                    <Expiry linkData={linkData} next={next} />
                  </div>
                )}
              />
              <Step
                id="Email"
                render={({ next }) => (
                  <div>
                    <Email
                      linkData={linkData}
                      next={next}
                      loading={loading}
                      contact={contact}
                    />
                  </div>
                )}
              />
              <Step
                id="Password"
                render={({ next }) => (
                  <div>
                    <Password
                      linkData={linkData}
                      next={next}
                      loading={loading}
                    />
                  </div>
                )}
              />
              <Step
                id="NDA"
                render={({ next }) => (
                  <div>
                    <NDA linkData={linkData} next={next} />
                  </div>
                )}
              />
              <Step
                id="Privacy"
                render={({ next }) => (
                  <div>
                    <Privacy linkData={linkData} next={next} />
                  </div>
                )}
              />
              <Step
                id="Collection"
                render={({ next }) => (
                  <>
                    <Collection
                      setActiveAsset={setActiveAsset}
                      activeAsset={activeAsset}
                      setLayout={setLayout}
                      layout={layout}
                      pageViewer={pageViewer}
                      handleSaveEvent={handleSaveEvent}
                      collectionData={collectionData}
                      openSection={openSection}
                      handleSectionChange={handleSectionChange}
                    />
                  </>
                )}
              />
            </Steps>
          </Wizard>
        </>
      )}
    </>
  );
};

export default View;
