import * as types from './actionTypes'
import {
  request,
  success,
  failure
} from '../../../app/utilities/helpers/'
import { linkService } from './_service'
import { toast } from 'react-toastify'


const handleGetLinkData = (id) => {
  return (dispatch) => {
    dispatch(request(null, types.GET_LINK_DATA_REQUEST))
    linkService.handleGetLinkData(id).then(
      (res) => {
        setTimeout(function () {
          dispatch(success(res, types.GET_LINK_DATA_SUCCESS))
        }, 500);
      },
      (error) => {
        dispatch(failure(error, types.GET_LINK_DATA_FAILED))
        if (error?.response?.data?.message) {
          if (error.response.data.message === 'Not found') {
            window.location.replace(`/notfound`)
          } else {
            toast.error(error?.response?.data?.message)
          }
        } else {
          toast.error("Something went wrong. It's us not you")
        }
      })
  }
}

const handleSaveEvent = (data) => {
  return (dispatch) => {
    dispatch(request(data, types.SAVE_EVENT_REQUEST))
    linkService.handleSaveEvent(data).then(
      (res) => {
        dispatch(success(res, types.SAVE_EVENT_SUCCESS))
        if (data.eventName === 'link_close') {
          dispatch({ type: 'CLEAR_STORE' })
        }
      },
      (error) => {
        dispatch(failure(error, types.SAVE_EVENT_FAILED))
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message)
        } else {
          toast.error("Something went wrong. It's us not you")
        }
      })
  }
}

const handleCreateLinkContact = (data, callBack) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_LINK_CONTACT_REQUEST))
    linkService.handleCreateLinkContact(data).then(
      (res) => {
        dispatch(success(res, types.CREATE_LINK_CONTACT_SUCCESS))
        setTimeout(function () {
          callBack && callBack(true)
          // dispatch(linkActions.handleGetLinkData(data.link))
        }, 500);
      },
      (error) => {
        callBack && callBack(false)
        dispatch(failure(error, types.CREATE_LINK_CONTACT_FAILED))
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message)
        } else {
          toast.error("Something went wrong. It's us not you")
        }
      }
    )
  }
}

const handleValidatePassword = (data, callBack) => {
  return (dispatch) => {
    dispatch(request(null, types.VALIDATE_LINK_PASSWORD_REQUEST))
    linkService.handleValidatePassword(data).then(
      (res) => {
        toast.success(res)
        dispatch(success(res, types.VALIDATE_LINK_PASSWORD_SUCCESS))
        setTimeout(function () {
          callBack && callBack(true)
        }, 500);
      },
      (error) => {
        callBack && callBack(false)
        dispatch(failure(error, types.VALIDATE_LINK_PASSWORD_FAILED))
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message)
        } else {
          toast.error("Something went wrong. It's us not you")
        }
      }
    )
  }
}

const handleCreateSession = (data, callBack) => {
  return (dispatch) => {
    dispatch(request(null, types.CREATE_SESSION_REQUEST))
    linkService.handleCreateSession(data).then(
      (res) => {
        dispatch(success(res, types.CREATE_SESSION_SUCCESS))
        setTimeout(function () {
          callBack && callBack(true)
        }, 500);
      },
      (error) => {
        callBack && callBack(false)
        dispatch(failure(error, types.CREATE_SESSION_FAILED))
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message)
        } else {
          toast.error("Something went wrong. It's us not you")
        }
      }
    )
  }
}

const handleSaveAssetState = (assets) => {
  return (dispatch) => {
    dispatch(success(assets, types.SAVE_ASSET_STATE))
  }
}

const handleSaveIdentity = (identity) => {
  return (dispatch) => {
    dispatch(success(identity, types.SAVE_IDENTITY))
  }
}

const handleGetLinks = (data) => {
  return (dispatch) => {
    dispatch(request(null, types.GET_LINKS_REQUEST))
    linkService.handleGetLinks(data).then(
      (res) => {
        setTimeout(function () {
          dispatch(success(res, types.GET_LINKS_SUCCESS))
        }, 500);
      },
      (error) => {
        dispatch(failure(error, types.GET_LINKS_FAILED))
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message)
        } else {
          toast.error("Something went wrong. It's us not you")
        }
      })
  }
}

const handleCompose = (data, handleClose) => {
  return (dispatch) => {
    dispatch(request(null, types.COMPOSE_REQUEST))
    linkService.handleCompose(data).then(
      (res) => {
        toast.success("Composed Successfully!")
        dispatch(success(res, types.COMPOSE_SUCCESS))
        setTimeout(() => { handleClose && handleClose(false) }, 300);
      },
      (error) => {
        dispatch(failure(error, types.COMPOSE_FAILED))
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message)
        } else {
          toast.error("Something went wrong. It's us not you")
        }
      }
    )
  }
}

export const linkActions = {
  handleGetLinkData,
  handleSaveEvent,
  handleCreateLinkContact,
  handleValidatePassword,
  handleCreateSession,
  handleSaveAssetState,
  handleSaveIdentity,
  handleGetLinks,
  handleCompose
}
