import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import LoadingSpinner from '../../../components/LoadingSpinner'

import { linkActions } from '../../../app/store/actions/'

const Password = ({ linkData, loading, next }) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState()

  useEffect(() => {
    if (linkData) {
      if (!linkData.password) {
        next()
      }
    }
  }, [linkData, next])

  const handleValidatePassword = () => {
    dispatch(linkActions.handleValidatePassword({
      password,
      link: linkData.id
    }, next))
  }

  if (!linkData?.password) {
    return null
  }


  return (
    <div className="w-full mb-5">
      <div className='relative'>
        <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
          <div className="w-full mx-2 my-5 p-7 border border-slate-300 rounded">
            <h3 className="font-bold">Password</h3>
            {/*display password input. user should enter password set to proceed */}
            <div>Please enter <b>Password</b> to View content</div>
            <div className="flex items-center">
              <input
                placeholder="Enter link password here"
                className="p-2 outline-none"
                name="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              className=":px-2 bg-[#000080] text-white hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl"
              type="button"
              onClick={() => handleValidatePassword()}
              disabled={loading}
              >
              {loading ? <LoadingSpinner /> : 'Submit'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Password
