import React from 'react';

const LoadingLinear = ({ LoadingProgressColor }) => {
    return (
        <div class="indeterminate-progress-bar">
            <div class="indeterminate-progress-bar__progress" style={{backgroundColor: LoadingProgressColor}}></div>
        </div>
    );
};

export default LoadingLinear;