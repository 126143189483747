export const GET_LINK_DATA_REQUEST = 'GET_LINK_DATA_REQUEST'
export const GET_LINK_DATA_SUCCESS = 'GET_LINK_DATA_SUCCESS'
export const GET_LINK_DATA_FAILED = 'GET_LINK_DATA_FAILED'

export const SAVE_EVENT_REQUEST = 'SAVE_EVENT_REQUEST'
export const SAVE_EVENT_SUCCESS = 'SAVE_EVENT_SUCCESS'
export const SAVE_EVENT_FAILED = 'SAVE_EVENT_FAILED'

export const CREATE_LINK_CONTACT_REQUEST = 'CREATE_LINK_CONTACT_REQUEST'
export const CREATE_LINK_CONTACT_SUCCESS = 'CREATE_LINK_CONTACT_SUCCESS'
export const CREATE_LINK_CONTACT_FAILED = 'CREATE_LINK_CONTACT_FAILED'

export const VALIDATE_LINK_PASSWORD_REQUEST = 'VALIDATE_LINK_PASSWORD_REQUEST'
export const VALIDATE_LINK_PASSWORD_SUCCESS = 'VALIDATE_LINK_PASSWORD_SUCCESS'
export const VALIDATE_LINK_PASSWORD_FAILED = 'VALIDATE_LINK_PASSWORD_FAILED'

export const CREATE_SESSION_REQUEST = 'CREATE_SESSION_REQUEST'
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS'
export const CREATE_SESSION_FAILED = 'CREATE_SESSION_FAILED'

export const CLEAR_STORE = 'CLEAR_STORE'

export const SAVE_ASSET_STATE = 'SAVE_ASSET_STATE'

export const SAVE_IDENTITY = 'SAVE_IDENTITY'

export const GET_LINKS_REQUEST = 'GET_LINKS_REQUEST'
export const GET_LINKS_SUCCESS = 'GET_LINKS_SUCCESS'
export const GET_LINKS_FAILED = 'GET_LINKS_FAILED'

export const COMPOSE_REQUEST = 'COMPOSE_REQUEST'
export const COMPOSE_SUCCESS = 'COMPOSE_SUCCESS'
export const COMPOSE_FAILED = 'COMPOSE_FAILED'
