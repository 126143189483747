import React from 'react';

import Router from './app/router/'

import './App.css';
import 'react-circular-progressbar/dist/styles.css';


const App = () => {
  return <Router />
};

export default App;
