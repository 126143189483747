import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import LoadingSpinner from "../../../components/LoadingSpinner";

import { linkActions } from "../../../app/store/actions/";

const Email = ({ linkData, contact, loading, next }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState();

  useEffect(() => {
    if (linkData) {
      if (!linkData.email_gate) {
        next();
      }
      // if (linkData.email_gate) {
      //   if (linkData.contact && linkData.contact.length > 0) {
      //     const contacts = linkData.contact.filter((item) => item.email === contact.email)
      //     if (contacts.length > 0) {
      //       next()
      //     }
      //   }
      // } else {
      //   next()
      // }
    }
  }, [linkData, next, contact]);

  const handleCreateLinkContact = () => {
    const payload = {
      email,
      link: linkData.id,
    };
    dispatch(linkActions.handleCreateLinkContact(payload, next));
  };

  if (!linkData.email_gate) {
    return null;
  }

  return (
    <div className="w-full mb-5">
      <div className="relative">
        <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
          <div className="w-full mx-2 my-5 p-7 border border-slate-300 rounded">
            {/*display email input, user should enter email to proceed */}
            <h3 className="font-bold">Email</h3>
            <div>
              Please enter your <b>email</b> to View content
            </div>
            <div className="flex items-center">
              <input
                placeholder="Enter recipient email here"
                className="p-2 outline-none"
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button
              className=":px-2 bg-[#000080] text-white hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl"
              type="button"
              onClick={() => handleCreateLinkContact()}
              disabled={loading}
            >
              {loading ? <LoadingSpinner /> : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Email;
