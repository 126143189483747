import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import App from './App';
import store from './app/store'
import { ContextProvider } from './app/context/ContextProvider';

// import 'materialize-css/dist/css/materialize.min.css'
import './index.css';

ReactDOM.render(
  <Provider store={store}>
  <ContextProvider>
    <ToastContainer />
    <App />
  </ContextProvider>
  </Provider>,
  document.getElementById('root')
);
