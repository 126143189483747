import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Layout from './components/Layout'
import { linkActions } from '../../../../app/store/actions/'
import './collection.css';


const Collection = ({ setActiveAsset, activeAsset, setLayout, layout, pageViewer, handleSaveEvent, collectionData, openSection, handleSectionChange }) => {
  const dispatch = useDispatch();
  const { linkData } = useSelector((state) => state.link);

  const handleOnAssetClick = (asset) => {
    const imageExtensions = ['jpeg', 'jpg', 'png']
    if (imageExtensions.includes(asset.name.split('.').at(-1))) {
      dispatch(linkActions.handleSaveAssetState({ [asset.id]: 100 }))
    }

    // check if asset was open; call closing events
    if (!activeAsset) {
      setActiveAsset(asset)
      setTimeout(function () {
        setLayout('vertical')
      }, 500);
    } else if (activeAsset && activeAsset.id !== asset.id) {
      // close events for asset being closed
      handleSaveEvent({ eventName: "view_stop", asset: activeAsset })
      setActiveAsset(asset)
      setTimeout(function () {
        setLayout('vertical')
      }, 500);
    }
  }

  return (
    <div className="w-full mb-5">
      <div className='relative'>
        <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
          {(collectionData && collectionData.id) || (activeAsset && activeAsset.id) ? (
            <Layout
              linkData={linkData}
              collectionData={collectionData}
              openSection={openSection}
              activeAsset={activeAsset}
              handleSaveEvent={handleSaveEvent}
              handleSectionChange={handleSectionChange}
              handleOnAssetClick={handleOnAssetClick}
              layout={layout}
              pageViewer={pageViewer} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Collection;
