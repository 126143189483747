import React, { useEffect, useState } from 'react'
import { Switch } from '@headlessui/react'

const NDA = ({ linkData, next }) => {
  const [accepted, setAccepted] = useState()
  const [nda, setNda] = useState({})

  useEffect(() => {
    if (linkData) {
      if (linkData?.nda && linkData?.nda_data.length) {
        setNda(linkData.nda_data[0])
      } else {
        next()
      }
    }
  }, [linkData, next])

  useEffect(() => {
    if (accepted) {
      next()
    }
  }, [accepted, next])

  if (!(linkData?.nda && linkData?.nda_data.length)) {
    return null
  }

  return (
    <div className="w-full mb-5">
      <div className='relative'>
        <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
          <div className="w-full mx-2 my-5 p-7 border border-slate-300 rounded">
            {/* display nda and accept checkbox, user should accept terms to proceed */}
            <h3 className="font-bold">{nda.title}</h3>
            <div>
              <span
                dangerouslySetInnerHTML={{ __html: nda.description }}
              />
              <div className="flex justify-between items-center w-1/2 pl-2 py-2">
                <div>Accept the NDA to proceed</div>{' '}
                <Switch
                  checked={accepted}
                  onChange={() => setAccepted(!accepted)}
                  className={`${accepted ? ' bg-uberark-blue' : ' bg-uberark-orange'}
                relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                  <span className="sr-only">NDA</span>
                  <span
                    aria-hidden="true"
                    className={`${accepted ? 'translate-x-5' : 'translate-x-0'}
                  pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NDA
