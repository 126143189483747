import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Pages routes
import { Landing, View, ErrorPg, Access, NotFound } from '../../pages';


const Router = () => {
  return (
    <BrowserRouter>
      <div className="flex relative">
        <div
          className={`bg-white h-fit min-h-screen w-full flex-2 relative`}
        >
          <div>
            <Routes>
              {/* Default paths */}
              <Route path="*" element={<ErrorPg />} />
              <Route path="/unauthorized" element={<Access />} />
              <Route path="/notfound" element={<NotFound />} />
              <Route path="/" element={<Landing />} />
              <Route path="/:token" element={<View />} />
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default Router;
