import * as types from '../actions/actionTypes'

const initialState = {
  loading: false,
  loadingEvent: false,
  loadingSession: false,
  event: [],
  linkData: {},
  links:[],
  contact: {},
  session: null,
  compose: null,
  message: '',
  errors: {},
  assetState: {},
  progressLoader: false,
  identity: null
}

const linkReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LINK_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        loadingEvent: false,
        loadingSession: false,
        event: [],
        linkData: {},
        links:[],
        contact: {},
        session: null,
        compose: null,
        message: '',
        errors: {},
        assetState: {}
      }
    case types.GET_LINK_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        linkData: action.data
      }
    case types.GET_LINK_DATA_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
      }

    case types.SAVE_EVENT_REQUEST:
      return {
        ...state,
        loadingEvent: true,
        errors: {},
        message: '',
        progressLoader: action.data.event_name === 'download' ? true:false
      }
    case types.SAVE_EVENT_SUCCESS:
      return {
        ...state,
        loadingEvent: false,
        progressLoader: false
      }
    case types.SAVE_EVENT_FAILED:
      return {
        ...state,
        errors: action.error,
        loadingEvent: false,
      }

    case types.CREATE_LINK_CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
        message: '',
      }
    case types.CREATE_LINK_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contact: action.data
      }
    case types.CREATE_LINK_CONTACT_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
      }

    case types.CREATE_SESSION_REQUEST:
      return {
        ...state,
        loadingSession: true,
        errors: {},
        message: '',
      }
    case types.CREATE_SESSION_SUCCESS:
      return {
        ...state,
        loadingSession: false,
        session: action.data
      }
    case types.CREATE_SESSION_FAILED:
      return {
        ...state,
        errors: action.error,
        loadingSession: false,
      }

    case types.CLEAR_STORE:
      return {
        loading: false,
        loadingEvent: false,
        loadingSession: false,
        event: [],
        linkData: {},
        links:[],
        contact: {},
        session: null,
        compose: null,
        message: '',
        errors: {},
        assetState: {}
      }

    case types.SAVE_ASSET_STATE:
      return {
        ...state,
        assetState: {...state.assetState, ...action.data },
      }

    case types.SAVE_IDENTITY:
      return {
        ...state,
        identity: action.data,
      }

    case types.GET_LINKS_REQUEST:
      return {
        ...state,
        loading: true,
        links: []
      }
    case types.GET_LINKS_SUCCESS:
      return {
        ...state,
        loading: false,
        links: action.data
      }
    case types.GET_LINKS_FAILED:
      return {
        ...state,
        errors: action.error,
        loading: false,
      }

    case types.COMPOSE_REQUEST:
      return {
        ...state,
        loadingEvent: true,
        compose: false,
        errors: {}
      }
    case types.COMPOSE_SUCCESS:
      return {
        ...state,
        compose: true,
        loadingEvent: false
      }
    case types.COMPOSE_FAILED:
      return {
        ...state,
        errors: action.error,
        compose: false,
        loadingEvent: false
      }

    default:
      return state
  }
}

export default linkReducer
