import React from 'react';
import { Link } from 'react-router-dom';

import { BiSearch } from 'react-icons/bi';

import errorimg from '../../assets/images/errorimg.svg';
import logo from '../../assets/images/logos/logo.svg';
import Divider from '../../assets/images/Divider.svg';


const ErrorPg = () => {
  return (
    <div className="absolute inset-0 bg-white">
      <div className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900 w-full">
        <img src={logo} alt="logo" />
        <span>Uberark</span>
      </div>
      <div className="flex flex-col gap-6 justify-center items-center">
        <img src={errorimg} alt="error" className="scale-75" />
        <span className="text-center text-[#77838F]">
          OOPPS! THE PAGE YOU WERE LOOKING FOR, COULDN'T BE FOUND.
        </span>
        <span className="text-center">
          Try the search below to find matching pages:
        </span>
        <div className="flex items-center">
          <label className="relative flex  w-96">
            <span className="sr-only">Search</span>
            <span className="absolute inset-y-0 left-0 flex items-center ml-6 pl-2">
              <BiSearch className="md:w-5 md:h-5 w-3 h-3" />
            </span>
            <input
              className="placeholder:text-slate-400 flex bg-white w-full rounded-2xl ml-6 py-2 pl-9 pr-3 shadow-[4px_4px_12px_rgba(0,0,0,0.05)]  focus:outline-none focus:border-[#008] focus:ring-[#008] focus:ring-1 sm:text-sm "
              placeholder="Search"
              type="text"
              name="search"
            />
          </label>
        </div>
        <img src={Divider} alt="error" />
        <Link
          className=":px-2 bg-[#000080] hover:bg-white text-white hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent sm:text-uberark-blue"
          style={{ border: '0.24px solid #000080' }}
          to="/"
        >
          Home
        </Link>
      </div>
    </div>
  );
};

export default ErrorPg;
