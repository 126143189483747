import React, { useEffect, useState } from 'react'
import { Switch } from '@headlessui/react'

import { isConsentRequired } from "../../../app/utilities/helpers/";



const Privacy = ({ linkData, next }) => {
    const [accepted, setAccepted] = useState()
    const [privacyData, setPrivacyData] = useState({})

    //   constent_none, constent_mandatory_eu_only, constent_mandatory, constent_optional
    useEffect(() => {
        if (linkData) {
            if (linkData?.privacy_data && linkData?.privacy_data?.id) {
                if (linkData?.privacy_data?.constent_none) {
                    next()
                } else if (linkData?.privacy_data?.constent_mandatory_eu_only) {
                    // check if user is from the EU region; if no, next
                    if (isConsentRequired()) {
                        setPrivacyData(linkData.privacy_data)
                    } else {
                        next()
                    }
                } else {
                    setPrivacyData(linkData.privacy_data)
                }
            } else {
                next()
            }
        }
    }, [linkData, next])

    useEffect(() => {
        if (accepted) { next() }
    }, [accepted, next])

    if (!(linkData?.privacy_data && linkData?.privacy_data?.id)) return null

    return (
        <div className="w-full mb-5">
            <div className='relative'>
                <div className="absolute sm:top-0 top-20 w-full h-full mb-5">
                    <div className="w-full mx-2 my-5 p-7 border border-slate-300 rounded">
                        {/* display policy and accept checkbox, user should accept terms to proceed */}
                        <h3 className="font-bold">Privacy Policy</h3>
                        <div>
                            {privacyData.custom_policy_content ? (
                                <span
                                    dangerouslySetInnerHTML={{ __html: privacyData.custom_policy_content }}
                                />
                            ) : null}
                            {(privacyData.custom_policy_url || privacyData.uberark_policy_url) ? (
                                <>
                                    Click
                                    <a
                                        href={privacyData.custom_policy_url || privacyData.uberark_policy_url}
                                        className="text-uberark-blue">
                                        {' '}here
                                    </a>
                                    to view the privacy policy
                                </>
                            ) : null}
                            <div className="flex justify-between py-5">
                            <div className="flex items-center pl-2 pt-2">
                                <div>Accept the Privacy Policy</div>{' '}
                                <Switch
                                    checked={accepted}
                                    onChange={() => setAccepted(!accepted)}
                                    className={`${accepted ? ' bg-uberark-blue' : ' bg-uberark-orange'}
                relative inline-flex h-4 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ml-2`}
                                >
                                    <span className="sr-only">Privacy Policy</span>
                                    <span
                                        aria-hidden="true"
                                        className={`${accepted ? 'translate-x-5' : 'translate-x-0'}
                  pointer-events-none inline-block h-[10px] w-[10px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                    />
                                </Switch>{' '}
                            </div>
                            {linkData?.privacy_data?.constent_optional ? (
                                <div className="flex items-center pl-2 py-2">
                                    <button
                                        onClick={() => setAccepted(!accepted)}
                                        className={`bg-uberark-blue px-5 py-2 border hover:bg-white hover:text-uberark-blue hover:border-uberark-blue text-white rounded-full`}
                                    >
                                        <span className="">Next</span>
                                    </button>
                                </div>
                            ) : null}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy
