import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FiXCircle } from "react-icons/fi";
import {
  HtmlEditor,
  Inject,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  Link
} from '@syncfusion/ej2-react-richtexteditor';
import File from '../../../../components/File';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { Combobox } from '@headlessui/react';

import { RichTextEditorToolbar } from '../../../../app/utilities/helpers';
import { linkActions } from '../../../../app/store/actions/';
import "./compose.css"


const Compose = ({ assets, onShareClose, handleSaveEvent, pageViewer }) => {
  const dispatch = useDispatch();
  const { loading, linkData } = useSelector(state => state.link)

  const [to, setTo] = useState([]);
  const [bcc, setBcc] = useState([]);
  const [cc, setCc] = useState([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState("<div>Hello there,</div><br><div>Here's a collection of assets exclusively personalised for you! Hope you find this material useful. I'd love to hear what you think about them. Please feel free to get in touch at any time.<br></div>")
  const [emailError, setEmailError] = useState('');
  const [formError, setFormError] = useState('');
  const [ccToggle, setCcToggle] = useState(false);
  const [bccToggle, setBccToggle] = useState(false);


  const formSubmit = (e) => {
    e.preventDefault();
    if (to.length && subject.length) {
      const attachments = assets

      let attachmentsIds = []
      if (attachments.length > 0) {
        attachmentsIds = attachments.map(attachment => {
          return attachment.id
        })
      } else {
        setFormError("Attach a file before sending.");
        return;
      }

      let payload = {
        "subject": subject.trim(),
        "message": message,
        "files": attachmentsIds,
        "to_email": to.length ? to : [],
        "cc": cc.length ? cc : [],
        "bcc": bcc.length ? bcc : [],
        "contact_id": pageViewer || null
      }

      if (linkData && linkData.composed_email) {
        payload['parent_email'] = linkData.composed_email
      }

      handleSaveEvent({ eventName: 'share' })

      return dispatch(linkActions.handleCompose(payload, onShareClose))
    } else {
      if (!to.length) {
        setFormError("Enter TO email address")
      } else if (to.length && !subject.length) {
        setFormError("Enter subject")
      }
    }
  };

  const onEmailChange = (name, e) => {
    let value = e.name ? e.name : e.target ? e.target.value : e.value
    setEmailError("")
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if ((e.which === 13 || e.which === 9 || (e.type === 'blur') || e.name) && value.length) {
      if (name === "to") {
        if (emailRegex.test(value)) {
          setTo([...to, value])
        } else {
          setEmailError("Invalid Email!")
          return;
        }
      } else if (name === "bcc") {
        if (emailRegex.test(value)) {
          setBcc([...bcc, value])
        } else {
          setEmailError("Invalid Email!")
          return;
        }
      } else if (name === "cc") {
        if (emailRegex.test(value)) {
          setCc([...cc, value])
        } else {
          setEmailError("Invalid Email!")
          return;
        }
      }
    }
  }

  const onRemoveEmail = (name, value) => {
    if (name === "to") {
      const toEmail = to.filter((email) => email !== value)
      setTo(toEmail)
    } else if (name === "bcc") {
      let bccEmail = bcc.filter((email) => email !== value)
      setBcc(bccEmail)
    } else if (name === "cc") {
      let ccEmail = cc.filter((email) => email !== value)
      setCc(ccEmail)
    }
  }


  return (
    <>
      <div className={`mx-4 md:pt-4 pt-4 grid grid-cols-1 md:grid-cols-2 justify-between bg-transparent`}>
        <div className="flex flex-col">
          <span className="text-[#FE4502] font-extrabold text-lg">
            Compose
          </span>
          <span className="text-[#757575] text-14">Quick Send</span>
        </div>
      </div>

      <div
        className={`border-t-1 border-t-slate-300 mt-2 bg-white text-slate-600 flex relative`}>
        {/* form */}
        <div className=" px-6 relative">
          <form onSubmit={formSubmit} className="relative w-full">
            <div className="relative flex p-2 md:p-4 w-full compose_body">
              {/* form */}
              <div className="compose_body flex flex-col w-full">

                <div className="flex flex-row compose-email-section py-1">
                  <div className="compose-email__input">
                    {(to.length) ? <div className='email-chip-wrap'>
                      {to.map((t, i) => {
                        return <p key={i}>{t.name ? t.name : t} <FiXCircle onMouseDown={() => onRemoveEmail('to', t)} /></p>
                      })}
                    </div> : null}
                    <Combobox name="to" value={to} onChange={(event) => onEmailChange("to", event)}>
                      <Combobox.Input
                        id="to"
                        onKeyDown={(e) => onEmailChange("to", e)}
                        onBlur={(e) => onEmailChange("to", e)}
                        onChange={(event) => onEmailChange("to", event)}
                        displayValue={to}
                        placeholder="to"
                        className="w-full" />
                    </Combobox>
                  </div>
                  <div className="flex justify-end">
                    <button
                      className={`${ccToggle ? 'text-[#FE4502]' : ''} px-2 cursor-pointer font-semibold`}
                      onClick={() => { setCcToggle(!ccToggle) }}
                      type="button">
                      Cc
                    </button>
                    <button
                      className={`${bccToggle ? 'text-[#FE4502]' : ''} px-2 cursor-pointer font-semibold`}
                      onClick={() => { setBccToggle(!bccToggle) }}
                      type="button">
                      Bcc
                    </button>
                  </div>
                </div>

                {ccToggle ? (
                  <div className="flex compose-email-section w-full py-1">
                    <div className="compose-email__input w-full">
                      {cc.length ? <div className='email-chip-wrap'>
                        {cc.map((t, i) => {
                          return <p key={i}>{t.name ? t.name : t} <FiXCircle onMouseDown={() => onRemoveEmail('cc', t)} /></p>
                        })}
                      </div> : null}
                      <Combobox name="cc" value={cc} onChange={(event) => onEmailChange("cc", event)}>
                        <Combobox.Input
                          id="cc"
                          onKeyDown={(e) => onEmailChange("cc", e)}
                          onBlur={(e) => onEmailChange("cc", e)}
                          onChange={(event) => onEmailChange("cc", event)}
                          displayValue={cc}
                          placeholder="CC"
                          className="w-full" />
                      </Combobox>
                    </div>
                  </div>
                ) : null}

                {bccToggle ? (
                  <div className="flex compose-email-section w-full py-1">
                    <div className="compose-email__input w-full">
                      {bcc.length ? <div className='email-chip-wrap'>
                        {bcc.map((t, i) => {
                          return <p key={i}>{t.name ? t.name : t} <FiXCircle onMouseDown={() => onRemoveEmail('bcc', t)} /></p>
                        })}
                      </div> : null}
                      <Combobox name="bcc" value={bcc} onChange={(event) => onEmailChange("bcc", event)}>
                        <Combobox.Input
                          onKeyDown={(e) => onEmailChange("bcc", e)}
                          onBlur={(e) => onEmailChange("bcc", e)}
                          onChange={(event) => onEmailChange("bcc", event)}
                          displayValue={bcc}
                          placeholder="BCC"
                          className="w-full" />
                      </Combobox>
                    </div>
                  </div>
                ) : null}

                {(emailError.length) ? <div className="text-red-600 compose-email-error mb-1">{emailError}</div> : null}

                <input
                  type="text"
                  placeholder="Subject"
                  className="py-1"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />

                <div className="pb-2 w-full relative">
                  <RichTextEditorComponent
                    change={(e) => setMessage(e.target.value)}
                    value={message}
                    toolbarSettings={RichTextEditorToolbar}
                    className="e-control e-richtexteditor e-lib e-rte-toolbar-enabled e-rte-tb-expand richtext-border-remove"
                  >
                    <Inject services={[Toolbar, Link, HtmlEditor, QuickToolbar]} />
                  </RichTextEditorComponent>
                </div>

              </div>
            </div>

            {(formError.length) ? <div className="text-red-600" style={{ margin: 15 }}>{formError}</div> : null}

            {/* footer */}
            <div className="flex sm:flex-row flex-col gap-4 p-4 justify-between items-end">
              <div className="flex flex-col w-full">
                <div className="child-left">
                  {assets.map((asset) => (
                    <File
                      key={asset.id}
                      file={asset?.thumbnail ? asset?.thumbnail : asset.file}
                      thumbnail={asset?.thumbnail || null}
                      name={asset.title || asset.name}
                      handleSaveEvent={() => {}}
                      containerClassName="sm:w-[108px] sm:h-[115px] w-20 h-20 rounded hover:bg-white hover:rounded-[20px] drop-shadow-xl"
                      unsupportedFileClassName="file-unsupported__sm" />
                  ))}
                </div>
              </div>

              <div className="flex flex-row items-center justify-center text-uberark-blue gap-4 p-2 text-lg">
                <button
                  className=":px-2 bg-[#000080] hover:bg-white text-white hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl "
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <LoadingSpinner /> : 'Send'}
                </button>
                <button
                  className=":px-2 bg-white hover:bg-white text-uberark-blue hover:text-[#000080] hover:drop-shadow-lg font-bold py-2 px-4 hover:scale-105 ease-in-out duration-200 rounded-2xl sm:bg-transparent "
                  style={{ border: '0.24px solid #000080' }}
                  onClick={(e) => onShareClose()}
                  type="button">
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>

      </div>

    </>
  );
};

export default Compose;
