import axios from "axios";
import store from "../../store";

export const requestDefaults = {
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    "Content-Type": "application/json",
  },
};

export const authHeader = () => {
  // return authorization header with jwt token
  if (store.getState().auth?.user?.token) {
    const token = store.getState().auth?.user?.token;
    return {
      Authorization: `Bearer ${token}`,
    };
  }

  return {};
};

// axios instance no authorization
export const axiosInstanceNoAuth = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Accept: "application/json",
  },
});

export const handleResponse = (res) => {
  if (res.status === 200 || res.status === 201) {
    // const { data } = res.data
    if (res?.data) {
      return res?.data;
    }
    return [];
  }
  const { response } = res;
  const { data } = response;
  const { message } = data;
  return Promise.reject(message);
};

export const handleResponseError = (error) => {
  const { response } = error;

  if (!response) {
    return error.toString();
  }

  const { data } = response;

  if (!data) {
    return error.toString();
  }

  const { message } = data;

  if (!message) {
    return error.toString();
  }

  return message;
};

//creating function to load ip address from the API
export const getUserIdentity = async () => {
  const res = await axios.get("https://geolocation-db.com/json/");
  const browserName = getUserBrowser();
  const osName = getOSName();
  return { ...res.data, ip: res.data.ip, browser: browserName, os: osName };
};

export const getUserBrowser = () => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Edge") !== -1) {
    return "Edge";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  ) {
    return "IE";
  } else {
    return "unknown";
  }
};

export const getOSName = () => {
  let OSName = "Unknown";
  if (window.navigator.userAgent.indexOf("Windows NT 10.0") !== -1)
    OSName = "Windows 10";
  if (window.navigator.userAgent.indexOf("Windows NT 6.3") !== -1)
    OSName = "Windows 8.1";
  if (window.navigator.userAgent.indexOf("Windows NT 6.2") !== -1)
    OSName = "Windows 8";
  if (window.navigator.userAgent.indexOf("Windows NT 6.1") !== -1)
    OSName = "Windows 7";
  if (window.navigator.userAgent.indexOf("Windows NT 6.0") !== -1)
    OSName = "Windows Vista";
  if (window.navigator.userAgent.indexOf("Windows NT 5.1") !== -1)
    OSName = "Windows XP";
  if (window.navigator.userAgent.indexOf("Windows NT 5.0") !== -1)
    OSName = "Windows 2000";
  if (window.navigator.userAgent.indexOf("Mac") !== -1) OSName = "Mac/iOS";
  if (window.navigator.userAgent.indexOf("X11") !== -1) OSName = "UNIX";
  if (window.navigator.userAgent.indexOf("Linux") !== -1) OSName = "Linux";

  return OSName;
};
